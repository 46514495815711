<template>
  <div class="br" ref="vlbox">
    <div class="index_main" ref="index_main">
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span>确定删除该条数据吗?</span>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false"
            >取 消</el-button
          >
          <el-button size="small" type="primary" @click="deletePro"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <div class="index_right">
        <p class="search">
          <el-input
            v-if="permissoin.includes('query')"
            type="text" clearable
            size="mini"
            v-model="searchForm.name"
            placeholder="名称、帐户、开户行等"
          ></el-input>
          <el-select
          placeholder="账户类型"
          v-model="searchForm.type"
          v-if="permissoin.includes('query')"
          size="mini"
          clearable
        >
          <el-option
            v-for="item in option222"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
          <button
            class="btn1"
            v-if="permissoin.includes('query')"
            @click="
              currentPage2 = 1;
              getData();
            "
          >
            <i class="el-icon-search"></i>
            查询
          </button>
          <button
            class="btn2"
            v-if="permissoin.includes('save')"
            @click="
              modal = 1;
              modalState = 0;
            "
          >
            <i class="el-icon-plus"></i>
            新增
          </button>
        </p>
        <div class="table srcollstyle">
          <el-table :data="tableData" style="width: 98%" border>
            <el-table-column type="index" label="排序" width="80">
              <template slot-scope="scope">
                       {{(currentPage2 - 1) * 10 + scope.$index + 1}}
                    </template>
            </el-table-column>
            <el-table-column prop="name" label="名称" width="200"
              :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column  label="类型"> 
                <template slot-scope="scope">
                       <span v-if="scope.row.type==1">电子汇款</span>
                       <span v-if="scope.row.type==2">对公帐户</span>
                    </template>
            </el-table-column>
            <el-table-column prop="bank" label="开户行" width="230"
              :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column prop="mobile" label="联系方式"> </el-table-column>
            <el-table-column
              prop="account"
              label="账户"
              width="200"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="160"
              :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column prop="updateTime" label="最新修改时间" width="160"
              :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-dropdown trigger="click" @command="handleCommand">
                  <el-button type="primary" plain size="small">
                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      icon="el-icon-edit-outline"
                      v-if="permissoin.includes('save')"
                      :command="{ num: 4, data: scope.row }"
                      >编辑</el-dropdown-item
                    ><el-dropdown-item
                      icon="el-icon-delete"
                      v-if="permissoin.includes('delete')"
                      :command="{ num: 3, data: scope.row }"
                      >删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="currentPage2"
            prev-text="上一页"
            next-text="下一页"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="modal" v-if="modal == 1">
      <div class="inner">
        <p class="title">
          <span v-if="modalState == 0">新增</span>
          <span v-if="modalState == 1">编辑</span>
          <span
            @click="
              form = {};
              modal = 0;
            "
            class="pointer"
          >
            <i class="el-icon-close"></i>
          </span>
        </p>
        <div class="modal_center scrollbar_width0">
          <el-form
            :model="form"
            :rules="rules"
            status-icon
            ref="ruleForm"
            label-width="80px"
            label-position="left"
          >
            <el-form-item label="类型" prop="type">
              <el-radio-group v-model="form.type" size="mini">
                <el-radio :label="1">电子汇款</el-radio>
                <el-radio :label="2">对公帐户</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="名称:" prop="name">
              <el-input v-model="form.name" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="账户:" prop="account">
              <el-input v-model="form.account" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="电话:" prop="mobile">
              <el-input v-model="form.mobile" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="开户行:" prop="bank">
              <el-input v-model="form.bank" size="mini"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="bottom">
          <button class="btn_red" @click="onsubmit">保存</button>
          <button
            class="btn_gray"
            @click="
              form = {};
              modal = 0;
            "
          >
            取消
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  baGetList,
  baSave,baDelete,
  wxGetOrgList,
  getSupplierList,
} from "@/api/apis.js";
export default {
  data() {
    return {
      options: [],
      form: {},
      info: 1,
      currentPage2: 1,
      treeData: [],
      hide: 1,
      modal: 0,
      treeList: [],
      total: 0,
      searchForm: {},
      permissoin: [],
      option4: [
        { name: "1%", id: 1 },
        { name: "3%", id: 3 },
        { name: "6%", id: 6 },
        { name: "9%", id: 9 },
        { name: "13%", id: 13 },
      ],
      option2: [{ name: "开票记录", id: 1 }],
      option222:[
        {
          value: "1",
          label: "电子汇款",
        },
        {
          value: "2",
          label: "对公帐户",
        },
      ],
      option3: [
        { name: "普通发票", id: 1 },
        { name: "增值税专用发票", id: 2 },
      ],
      modalState: 0,
      option5: [],
      option6: [],
      option8: [],
      option10: [],
      fileList: [],
      projectP: -1,
      dialogVisible: false,
      netArrUpdate: [{ key: 0 }],
      ruleForm: {
        pwd: 123456,
      },
      billForm: {},
      props: {
        label: "name",
        value: "id",
        children: "children",
        checkStrictly: true,
      },
      option1: [
        {
          value: "1",
          label: "已作废",
        },
        {
          value: "2",
          label: "待审核",
        },
        {
          value: "3",
          label: "被驳回",
        },
      ],
      
      srcList: [],
      tableData: [],
      rules: {
        // projectId: [
        //   { required: true, message: "请选择项目或代理商", trigger: "change" },
        // ],
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        mobile: [{ required: true, message: "请输入电话", trigger: "blur" }],
        bank: [{ required: true, message: "请输入开户行", trigger: "blur" }],
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入电话", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    getData() {
      let data = {
        name: this.searchForm.name,
        type: this.searchForm.type,
        pageNum: this.currentPage2,
        pageSize: 10,
      };
      baGetList(data).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.total = res.data.total - 0;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage2 = val;
      this.getData();
    },

    onsubmit() {
      let data={}
      if(this.modalState==0){
data = {
        name: this.form.name,
        mobile: this.form.mobile,
        bank: this.form.bank,
        account: this.form.account,
        type: this.form.type,
      };
      } else if(this.modalState==1){
          data = {
        id: this.form.id,
        name: this.form.name,
        mobile: this.form.mobile,
        bank: this.form.bank,
        account: this.form.account,
        type: this.form.type,
      };
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          baSave(data).then((res) => {
            if (res.code == 200) {
              this.getData();
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.modal = 0;
              this.form = {};
            } else {
              this.$message.error(res.data.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    handleCommand(d) {
      this.modalState = d.num;
      if (d.num == 2 || d.num == 4) {
        this.form =JSON.parse(JSON.stringify(d.data))
        this.modal = 1;
        this.modalState = 1;
      }else if(d.num==3){
        this.dialogVisible=true
        this.deleteId=d.data.id
      }
    },
    deletePro(){
      baDelete({id:this.deleteId}).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message:'删除成功',
          });
          this.dialogVisible = false;
          this.getData();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
  mounted() {
    //获取权限
    let arr = JSON.parse(localStorage.getItem("perArr"));
    let routePath = this.$route.path;
    arr.forEach((item) => {
      if (item.purl == routePath) {
        this.permissoin.push(item.permission);
      }
    });
    this.getData();
    wxGetOrgList({ pageSize: 0 }).then((res) => {
      if (res.code == 200) {
        this.option5 = res.data.list;
      }
    });
    getSupplierList().then((res) => {
      if (res.code == 200) {
        this.option6 = res.data;
      }
    });
  },
};
</script>

<style lang="less" >
.br {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 16px 18px;
  box-sizing: border-box;
  display: flex;
  .index_main {
    flex: 1;
    background-color: #fff;
    display: flex;
  }
  aside {
    width: 320px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .title {
      line-height: 40px;
      margin-top: 10px;
      width: 320px;
      box-sizing: border-box;
      padding: 0 8px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;

      img {
        margin-right: 6px;
      }
      .el-select {
        .el-input {
          width: 100px;
        }
        .el-input__inner {
          border: none;
        }
      }
    }
    .search {
      padding-left: 10px;
      .el-input-group__append {
        padding: 0 6px;
        background-color: #e7eaef;
        border: none;
        cursor: pointer;
      }
      .el-input {
        position: relative;
        top: 14px;
        width: 300px;
        .el-input__inner {
          background-color: #e7eaef;
          border: none;
        }
      }
    }
    .company {
      height: 780px;
      box-sizing: border-box;
      margin-top: 30px;
      .project_item {
        border-bottom: 1px dashed #ebf0f5;
        font-size: 12px;
        padding-left: 10px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      .active {
        color: #466cd4;
      }
    }
  }
  .index_right {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 28px;
    box-sizing: border-box;
    .search {
      margin: 22px 0;
      .el-input {
        width: 208px;
        margin-right: 20px;
      }
    }
    .table {
      flex: 1;
    }
    .block {
      .el-pagination {
        padding: 10px 18px;
      }
    }
    .hide {
      position: absolute;
      top: 48%;
      left: 0px;
      cursor: pointer;
    }
  }
  .modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    align-items: center;
    .inner {
      width: 456px;
      max-height: 800px;
      box-sizing: border-box;
      background-color: white;
      margin: auto;
      font-size: 12px;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      position: relative;
      box-shadow: 1px 1px 4px #888888;
      .title {
        color: #2b2e36;
        line-height: 40px;
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        padding: 0 30px;
        border-bottom: 1px solid #f1f0f0;
        i {
          font-size: 16px;
          cursor: pointer;
        }
      }
      .modal_center {
        flex: 1;
        padding: 0 34px;
        box-sizing: border-box;
        padding-top: 20px;
        .el-button--mini,
        .el-button--mini.is-round {
          padding: 6px 12px;
          margin-left: 4px;
        }
        .jstitle {
          span {
            display: inline-block;
            margin-right: 12px;
          }
          & > span:nth-child(1) {
            width: 160px;
          }
          & > span:nth-child(2) {
            width: 80px;
          }
          & > span:nth-child(3) {
            width: 80px;
          }
        }
        .net {
          .line {
            border-top: 1px dashed rgb(221, 214, 214);
            width: 348px;
          }
          p {
            margin-bottom: 20px;
          }
          .spaa {
            display: inline-block;
            width: 80px;
          }
          .el-select {
            width: 160px;
            margin-right: 12px;
            .el-input {
              width: 160px;
            }
          }
          .el-input {
            width: 80px;
            margin-right: 12px;
            .el-input__inner {
              border: none;
              padding: 0;
            }
          }
          .star {
            color: #ff2825;
            font-size: 14px;
            position: relative;
            top: 2px;
          }
        }
        .sel {
          display: flex;
          margin-bottom: 14px;
          line-height: 26px;
          .el-input__inner {
            width: 200px;
          }
          .el-select .el-input {
            width: 200px;
          }
        }
        .blue {
          color: #0090ff !important;
          font-weight: bold;
        }
        .red {
          color: #ff2825 !important;
          font-weight: bold;
        }
        .manyitem {
          color: #000;
          margin-bottom: 14px;

          .title1 {
            display: flex;
            justify-content: space-between;
            padding-right: 20px;
            font-size: 14px;
            p {
              font-weight: bold;
            }
          }
        }
        .el-table th {
          background-color: white;
          padding: 0px;
        }
        .twoitem {
          display: flex;
          justify-content: space-between;
          margin-bottom: 14px;
        }
        .spaa {
          display: inline-block;
          width: 83px;
          margin-left: -9px;
          color: rgba(0, 0, 0);
        }
        .star {
          color: #ff2825;
          font-size: 14px;
          position: relative;
          top: 2px;
        }
        .spa {
          display: inline-block;
          width: 75px;
          color: rgba(0, 0, 0);
        }
        .text_item {
          margin-bottom: 12px;
          & > span:nth-child(1) {
            color: #f56c6c;
            margin-right: 2px;
          }
          & > span:nth-child(2) {
            display: inline-block;
            margin-left: 198px;
            cursor: pointer;
            color: #d62829;
            i {
              font-weight: 600;
              font-size: 14px;
            }
          }
        }

        .line {
          border-top: 1px dashed rgb(221, 214, 214);
          width: 348px;
        }
        p {
          margin-bottom: 20px;
        }
        .spaa {
          display: inline-block;
          width: 80px;
        }
        .el-input {
          width: 270px;
        }
        .star {
          color: #ff2825;
          font-size: 14px;
          position: relative;
          top: 2px;
        }
      }
      .bottom {
        height: 60px;
        display: flex;
        justify-content: center;
        .btn_red {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>